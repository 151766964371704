<!--报关资料-->
<template>
<div>
    <ctrl-box class="ctrl-box" :data="{}">
        <template>
            <div class="xgroup">
                <el-link :underline="false" @click="showQuery = !showQuery">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-search" font-size="27px;"/>
                        <span class="txt" >{{msg('搜索')}}</span>   
                    </div>
                </el-link>
                <el-link v-if="hasPermission('declare:creates')" :underline="false" @click="doImport()">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-daoru3" font-size="27px;"/>
                        <span class="txt">{{msg('导入')}}</span>      
                    </div>            
                </el-link>
                <el-link v-if="hasPermission('declare:export')" :underline="false" @click="doExport()">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                        <span class="txt">{{msg('导出')}}</span>      
                    </div>            
                </el-link>
                <el-link v-if="hasPermission('declare:lock')" :underline="false"  @click="doLock(0)">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-jinyong" font-size="27px;"/>
                        <span class="txt">{{msg('锁单')}}</span>      
                    </div>            
                </el-link>
                <el-link v-if="hasPermission('declare:lock')" :underline="false"  @click="doLock(1)">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-jiechusuoding" font-size="27px;"/>
                        <span class="txt">{{msg('解锁')}}</span>      
                    </div>            
                </el-link>
                <el-link v-if="hasPermission('declare:delete')" :underline="false" @click="doDelete()">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                        <span class="txt">{{msg('删除')}}</span>      
                    </div>            
                </el-link> 
                <el-link v-if="hasPermission('declare:lock')" :underline="false"  @click="doLock(2)">
                    <div class="wrap" >
                        <icon-svg icon-class="icon-jinyong" font-size="27px;"/>
                        <span class="txt">{{msg('强制锁单')}}</span>      
                    </div>            
                </el-link>
            </div>
        </template>
    </ctrl-box>

    <!--查询区域-->
    <transition name="el-zoom-in-top">
        <query-box :query="query" v-show="showQuery" labelWidth="80px">
            <template v-slot:default="scope">
                <el-form-item :label="msg('报关单号')">
                    <el-input type="textarea"  :rows="3" v-model="scope.query.codes" style="margin-top:0px;">
                    </el-input>
                </el-form-item>
                <el-form-item :label="msg('调用单号')">
                    <el-input type="textarea"  :rows="3" v-model="scope.query.refCodes" style="margin-top:0px;">
                    </el-input>
                </el-form-item>
                <el-form-item :label="msg('调用状态')">
                    <el-select v-model="scope.query.status" multiple clearable @clear="clearStatus">
                        <el-option
                            v-for="item in dicts.declareStatuses"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="msg('配送方式')" >
                    <el-select v-model="scope.query.expCompany" clearable @clear="clearCompany">
                        <el-option
                            v-for="item in expCompanys"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="msg('配送类型')" >
                    <el-select v-model="scope.query.expCod" clearable @clear="clearCod">
                        <el-option
                            v-for="item in dicts.declareTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="msg('报关发件人')" label-width="100">
                    <el-input v-model="scope.query.sender"  clearable></el-input> 
                </el-form-item>
                <el-form-item :label="msg('报关人名')">
                    <el-input v-model="scope.query.fullname"  clearable></el-input> 
                </el-form-item>
                <el-form-item :label="msg('报关电话')">
                    <el-input v-model="scope.query.tel"  clearable></el-input> 
                </el-form-item>
                <el-form-item :label="msg('创建日期')">
                    <el-date-picker
                        v-model="scope.query.Date"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        clearable
                        :range-separator="msg('至')"
                        :start-placeholder="msg('开始日期')"
                        :end-placeholder="msg('结束日期')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="msg('调用日期')">
                    <el-date-picker
                        v-model="scope.query.RefTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        clearable
                        :range-separator="msg('至')"
                        :start-placeholder="msg('开始日期')"
                        :end-placeholder="msg('结束日期')">
                    </el-date-picker>
                </el-form-item>
                <el-row>
                    <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                </el-row>
            </template>
        </query-box>
    </transition>

     <div class="table-wrap">
        <vxe-grid ref="dataTable" :height="docHeight" size="mini"
            resizable border export-config
            show-overflow highlight-hover-row
            highlight-current-row highlight-hover-column highlight-current-column
            :data="datas"
            :columns="columns"
            @cell-click="checkRow"
        ></vxe-grid>

        <pagenator
            @page-change="handlerPageChange"
            :query="query"
        >
        </pagenator>
    </div>

    <el-dialog
        :title="msg('导入报关资料')" :fullscreen="isMinScreen"
        :visible.sync="iptVisible"
        :close-on-click-modal="false"
        :before-close="doCloseImport"
        width="70%">
            <declare-import @close-dg="iptVisible=false"/>
    </el-dialog>

</div>

</template>

<script>
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import DeclareImport from "./DeclareImport.vue";

export default {
    name: "Declare",
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator: Pagenator,
        DeclareImport: DeclareImport
    },
    mounted(){
        this.getCompanys();
        this.buildColumns();
        this.list();
    },
    data(){
        return {
            query: {
                codes: null,
                refCodes: null,
                status: [],
                expCompany: null,
                expCod: null,
                sender: null,
                fullname: null,
                tel: null,
                Date: [],
                RefTime:[],
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },
            showQuery: false,
            datas: [],
            columns: [],
            expCompanys:[],

            iptVisible: false,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods: {
        buildColumns(){
            let _this = this;
            this.columns = [
                {type: 'checkbox', minWidth: 60, sortable:true },
                {field: "id", title: _this.msg("ID"), minWidth: 90, sortable:true},
                {field: "created", title: _this.msg("创建时间"), minWidth: 110, sortable:true,
                    filters:[{data: []}],
                    filterRender:{
                        name: 'ElDatePicker', 
                        props: {
                            type: 'daterange', 
                            rangeSeparator: '至', startPlaceholder: '开始日期', 
                            endPlaceholder: '结束日期'
                        }
                    },
                },
                {field: "sender", title: _this.msg("报关发件人"), minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "code", title: _this.msg("报关单号"), minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "fullname", title: _this.msg("报关人名"), minWidth: 110, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "tel", title: _this.msg("报关电话"), minWidth: 110, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "address", title: _this.msg("报关地址"), minWidth: 110, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "cardCode", title: _this.msg("报关证号"), minWidth: 110, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "lockerName", title: _this.msg("锁定人"), minWidth: 110, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "expCompany", title: _this.msg("配送方式"), minWidth: 110, sortable:true,
                    filters:_this.expCompanys,
                },
                {field: "expCod", title: _this.msg("配送类型"), minWidth: 110, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            let status = _this.dicts.declareTypes.find(d => d.value === row.expCod);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.expCod}</el-tag>
                            ];
                        },
                    },
                },
                {field: "refStatus", title: _this.msg("调用状态"), minWidth: 110, sortable:true, 
                    filters:_this.dicts.declareStatuses,
                    slots: {
                        default: ({ row }) => {
                            let status = _this.dicts.declareStatuses.find(d => d.value === row.refStatus);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.refStatus}</el-tag>
                            ];
                        },
                    },
                    formatter : this.formatStatus
                },
                {field: "refQty", title: _this.msg("可调用次数"), minWidth: 120, sortable:true,
                },
                {field: "refCode", title: _this.msg("调用单号"), minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "refTime", title: _this.msg("调用时间"), minWidth: 110, sortable:true,
                    filters:[{data: []}],
                    filterRender:{
                        name: 'ElDatePicker', 
                        props: {
                            type: 'daterange', 
                            rangeSeparator: '至', startPlaceholder: '开始日期', 
                            endPlaceholder: '结束日期'
                        }
                    },
                },
                {field: "createName", title: _this.msg("创建人"), minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
            ]
        },
        formatStatus({ cellValue }){
            let status = this.dicts.declareStatuses.find(d => d.value === cellValue);
            return status.label || cellValue;
        },
        clearStatus(){
            this.query.status = null;
        },
        clearCompany(){
            this.query.expCompany = null;
        },
        clearCod(){
            this.query.expCod = null;
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        list(refresh){ 

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            let statusSelec = [];
            if(this.query.status.length > 0){
                statusSelec = this.query.status;
                this.query["status"] = this.query.status.join(",");
            }
            
            let params = this.$kit.rebuildQuery(this.query);
            if(params.codes){
                let cs = this.query.codes.split(/[\s,]+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                params.codes = css.join(",");
            }
            if(params.refCodes){
                let cs = this.query.refCodes.split(/[\s,]+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                params.refCodes = css.join(",");
            }

            this.$axios.post(this.$kit.api.declare.list, params)
                .then((result) => {
                    if(result.status){
                        this.datas = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }
                    this.query.status = statusSelec;
                });
        },
        reset(){
            this.query.codes= null;
            this.query.refCodes= null;
            this.query.status= [];
            this.query.expCompany= null;
            this.query.expCod = null;
            this.query.sender= null;
            this.query.fullname= null;
            this.query.tel= null;
            this.query.Date=[];
            this.query.RefTime=[];
            this.query.start = 0;
        },
         //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        doImport(){
            this.iptVisible = true;
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let isOk = true;
            selectes.forEach(e => {
                if(e.status == 1){
                    isOk = false;
                }
            });
           
            let ids = selectes.map(e => e.id).join(",");
            this.$confirm('确定删除（'+selectes.map(e => e.code).join(",")+'）', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.declare.deletes+'?ids='+ids)
                .then((result) => {
                    this.$message(result.msg);
                    this.list();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });          
            });
        },
        doCloseImport(done){
            this.list();
            done();
        },
        doLock(type){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let msg = "";
            if(type == 0 || type == 2){
                msg = "是否要进行锁单操作？";
            }else{
                msg = "是否要进行解锁？";
            }

            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                if(type == 0 || type == 2){
                    //锁单操作，添加一个发件人的录入框，保存到报关发件人字段
                    this.$prompt("请输入发件人", this.msg('提示'), {
                            closeOnClickModal:false,
                            confirmButtonText: this.msg('确定'),
                            cancelButtonText: this.msg('取消'),
                        }).then(({ value }) => {
                            this.lock(selectes,type,value);
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '取消输入'
                            });
                        });
                }else{
                    //解锁
                    this.lock(selectes,type,null);
                }
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });          
            });
        },
        lock(selectes, type,sender){
            let ids = selectes.map(e => e.id).join(",");
            this.$axios.post(this.$kit.api.declare.lock, "ids="+ids+"&type="+type+"&sender="+sender, {headers:{'Content-Type':'application/x-www-form-urlencoded'}})
                .then((result) => {
                    this.$message(result.msg);
                    this.list();
                });
        }
    }
    
}

</script>

<style lang="scss" scoped>

</style>